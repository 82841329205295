//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import CurrencyPairs from '~/views/Markets/MarketsCurrencyPairs/MarketsCurrencyPairs.vue';

export default {
  components: {
    CurrencyPairs,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.loadTradingOptionsAction(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('marketsCurrencyPairs', {
      loadMarketsCurrencyPairs: 'loadData',
      loadTradingOptionsAction: 'loadTradingOptions',
    }),
  },
};
