import { render, staticRenderFns } from "./MarketsCurrencyPairsModal.vue?vue&type=template&id=749ee508&scoped=true&"
import script from "./MarketsCurrencyPairsModal.vue?vue&type=script&lang=js&"
export * from "./MarketsCurrencyPairsModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "749ee508",
  null
  
)

export default component.exports