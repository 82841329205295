export default [
  {
    width: 6,
    header: {
      type: 'text',
      caption: 'Id',
    },
    cell: {
      type: 'text',
      value: (e) => e.id,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Markets',
    },
    cell: {
      type: 'text',
      value: (e) => e.name,
    },
  },
  {
    width: 10,
    header: {
      type: 'text',
      caption: 'Volume',
      sort: 'volume',
    },
    cell: {
      type: 'slot',
      name: 'volume',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Order amount',
    },
    cell: {
      type: 'slot',
      name: 'orderAmount',
    },
  },
  {
    width: 22,
    header: {
      type: 'text',
      caption: 'Order price',
    },
    cell: {
      type: 'slot',
      name: 'orderPrice',
    },
  },
  {
    width: 9,
    header: {
      type: 'text',
      caption: 'Limits type',
    },
    cell: {
      type: 'slot',
      name: 'limitsType',
    },
  },
  {
    width: 6,
    header: {
      type: 'text',
      caption: 'Sort id',
    },
    cell: {
      type: 'text',
      value: (e) => e.sortId,
    },
  },
  {
    width: 9,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 6,
    header: {
      type: 'edit',
      caption: '',
    },
    cell: {
      type: 'slot',
      name: 'edit',
    },
  },
];
