//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  props: {
    value: { type: Boolean, default: false },
    data: { type: Object, default: () => ({}) },
    isCreating: { type: Boolean, default: false },
  },

  data() {
    return {
      model: {
        baseCurrencyId: 0,
        derivedCurrencyId: 0,
        id: 0,
        isInUse: false,
        orderAmountLimit: false,
        maxOrderAmount: '',
        maxOrderPrice: '',
        minOrderAmount: '',
        minOrderPrice: '',
        cumulativeMaxOrderAmount: '',
        cumulativeMaxOrderPrice: '',
        cumulativeMinOrderAmount: '',
        cumulativeMinOrderPrice: '',
        cumulativeCurrencyId: 0,
        name: '',
        sortId: '',
      },
    };
  },

  computed: {
    ...mapState('currencies', ['currencies']),
    ...mapGetters('currencies', ['getCurrencyById']),

    showModal: {
      get() {
        const { value } = this;
        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    cumulativeCurrencyTitle() {
      const { cumulativeCurrencyId } = this.model;
      const currency = this.getCurrencyById(cumulativeCurrencyId);

      if (!currency) return '';
      return currency.title;
    },

    baseTitle() {
      const { baseCurrencyId } = this.model;
      const currency = this.getCurrencyById(baseCurrencyId);

      if (!currency) return '';
      return currency.title;
    },

    derivedTitle() {
      const { derivedCurrencyId } = this.model;
      const currency = this.getCurrencyById(derivedCurrencyId);

      if (!currency) return '';
      return currency.title;
    },
  },

  watch: {
    value(newVal) {
      if (newVal) {
        const { data } = this;
        this.model = data;
      }
    },
  },

  methods: {
    ...mapActions('common', ['confirmAction']),

    create() {
      this.confirmAction({
        text: 'Are you sure you want to create new market?',
        callback: () => {
          const {
            baseCurrencyId,
            derivedCurrencyId,
            isInUse,
            orderAmountLimit,
            maxOrderAmount,
            maxOrderPrice,
            minOrderAmount,
            minOrderPrice,
            sortId,
          } = this.model;
          this.$emit('create', {
            baseCurrencyId,
            derivedCurrencyId,
            isInUse,
            orderAmountLimit,
            maxOrderAmount: orderAmountLimit ? maxOrderAmount : null,
            maxOrderPrice,
            minOrderAmount: orderAmountLimit ? minOrderAmount : null,
            minOrderPrice,
            sortId,
          });
        },
      });
    },

    edit() {
      this.confirmAction({
        text: 'Are you sure you want to edit market?',
        callback: () => {
          const {
            id,
            isInUse,
            orderAmountLimit,
            maxOrderAmount,
            maxOrderPrice,
            minOrderAmount,
            minOrderPrice,
            sortId,
          } = this.model;
          this.$emit('edit', {
            marketId: id,
            isInUse,
            orderAmountLimit,
            maxOrderAmount: orderAmountLimit ? maxOrderAmount : null,
            maxOrderPrice,
            minOrderAmount: orderAmountLimit ? minOrderAmount : null,
            minOrderPrice,
            sortId,
          });
        },
      });
    },
  },
};
