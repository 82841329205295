//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import Filters from './MarketsCurrencyPairsFilters.vue';
import MarketModal from './MarketsCurrencyPairsModal.vue';

import tableConfig from './tableConfig';

export default {
  components: { Filters, MarketModal },

  data() {
    return {
      tableConfig,
      selection: [],
      loader: false,
      creatingMarket: {
        modal: false,
        isCreating: false,
        input: {},
      },
      commonOrderSettings: {
        loader: false,
        input: {
          baseCurrencyId: 0,
          minOrder: '',
          maxOrder: '',
        },
      },
    };
  },

  computed: {
    ...mapState('common', ['generalLoader']),
    ...mapGetters('common', ['itemsPerPage']),
    ...mapState('currencies', ['currencies']),
    ...mapGetters('currencies', ['getCurrencyById', 'toCurrencyFormat']),

    ...mapState('marketsCurrencyPairs', [
      'page',
      'pages',
      'data',
      'additionalData',
      'tradingOptions',
      'rates',
      'sort',
    ]),
  },

  watch: {
    tradingOptions() {
      const { $utils } = this;
      const { baseCurrencyId, minOrder, maxOrder } = this.tradingOptions;
      this.commonOrderSettings.input.baseCurrencyId = baseCurrencyId;
      this.commonOrderSettings.input.minOrder = $utils.sciToDec(minOrder);
      this.commonOrderSettings.input.maxOrder = $utils.sciToDec(maxOrder);
    },
  },

  methods: {
    ...mapActions('common', [
      'setGeneralLoader',
      'setGeneralProgress',
      'setSuccessNotification',
      'setErrorNotification',
    ]),
    ...mapActions('marketsCurrencyPairs', {
      loadDataAction: 'loadData',
      setPageAction: 'setPage',
      setSortAction: 'setSort',
      createMarketAction: 'createMarket',
      editMarketAction: 'editMarket',
      updateTradingOptionsAction: 'updateTradingOptions',
      loadTradingOptionsAction: 'loadTradingOptions',
    }),

    async loadData(data = {}) {
      this.setGeneralProgress(true);
      this.loader = true;
      await this.loadDataAction(data);
      this.setGeneralProgress(false);
      this.loader = false;
    },

    applyFilters() {
      const { page } = this;
      if (page > 1) {
        this.setPageAction(1);
        this.$router.replace({ query: {} });
      }

      this.loadData();
    },

    applySort(data) {
      this.setSortAction(data);
      this.loadData();
    },

    setPage(value) {
      this.setPageAction(value);

      const extendedRequestData = {
        getTotal: false,
      };

      this.loadData(extendedRequestData);
    },

    actionHandler({ action, payload }) {
      this[action](payload);
    },

    getCurrencyTitleById(id) {
      const currency = this.getCurrencyById(id);
      if (!currency) return '';
      return currency.title;
    },

    openModalEditMarket(row) {
      const { $utils } = this;
      this.creatingMarket.modal = true;
      this.creatingMarket.isCreating = false;
      const model = {
        ...row,
        maxOrderAmount: $utils.sciToDec(row.maxOrderAmount),
        maxOrderPrice: $utils.sciToDec(row.maxOrderPrice),
        minOrderAmount: $utils.sciToDec(row.minOrderAmount),
        minOrderPrice: $utils.sciToDec(row.minOrderPrice),
        cumulativeMaxOrderAmount: $utils.sciToDec(row.cumulativeMaxOrderAmount),
        cumulativeMaxOrderPrice: $utils.sciToDec(row.cumulativeMaxOrderPrice),
        cumulativeMinOrderAmount: $utils.sciToDec(row.cumulativeMinOrderAmount),
        cumulativeMinOrderPrice: $utils.sciToDec(row.cumulativeMinOrderPrice),
        cumulativeCurrencyId: this.additionalData.cumulativeCurrencyId,
        sortId: row.sortId.toString(),
      };
      this.creatingMarket.input = model;
    },

    openModalCreateMarket() {
      this.creatingMarket.modal = true;
      this.creatingMarket.isCreating = true;
      this.creatingMarket.input = {
        baseCurrencyId: 0,
        derivedCurrencyId: 0,
        id: 0,
        isInUse: false,
        orderAmountLimit: false,
        maxOrderAmount: '',
        maxOrderPrice: '',
        minOrderAmount: '',
        minOrderPrice: '',
        cumulativeMaxOrderAmount: '',
        cumulativeMaxOrderPrice: '',
        cumulativeMinOrderAmount: '',
        cumulativeMinOrderPrice: '',
        cumulativeCurrencyId: this.additionalData.cumulativeCurrencyId,
        name: '',
        sortId: '',
      };
    },

    async createMarket(data) {
      this.setGeneralProgress(true);
      try {
        await this.createMarketAction(data);
        this.loader = true;
        await this.loadDataAction({});
        this.loader = false;
        this.setSuccessNotification('New market successfully created');
        this.creatingMarket.modal = false;
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },

    async editMarket(data) {
      this.setGeneralProgress(true);
      try {
        await this.editMarketAction(data);
        this.loader = true;
        await this.loadDataAction({});
        this.loader = false;
        this.setSuccessNotification('Market successfully edited');
        this.creatingMarket.modal = false;
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.setGeneralProgress(false);
    },

    async updateTradingOptions() {
      this.commonOrderSettings.loader = true;
      try {
        const { baseCurrencyId, minOrder, maxOrder } = this.commonOrderSettings.input;
        await this.updateTradingOptionsAction({
          baseCurrencyId,
          minOrder,
          maxOrder,
        });
        this.setSuccessNotification('Common order settings updated');
        this.setGeneralProgress(true);
        await this.loadTradingOptionsAction();
        this.setGeneralProgress(false);
      } catch (error) {
        this.setErrorNotification(error.message);
      }
      this.commonOrderSettings.loader = false;
    },

    getOrderLimitEquivalent(value, marketBaseCurrencyId) {
      const { rates } = this;
      const commonBaseCurrencyId = this.tradingOptions.baseCurrencyId;
      const rate = rates.find((e) => (
        e.baseCurrencyId === commonBaseCurrencyId
        && e.derivedCurrencyId === marketBaseCurrencyId
      ));

      if (!rate) return '';
      const result = value * rate.price;
      return this.toCurrencyFormat(result, marketBaseCurrencyId);
    },
  },
};
