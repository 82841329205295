//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';
import constant from '~/const';

export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },

  data() {
    return {
      filters: {
        name: '',
        cumulativeCurrencyId: 0,
        ignoreUserTagList: false,
      },
      constant,
    };
  },

  computed: {
    ...mapState('marketsCurrencyPairs', ['filterLists', 'tradingOptions']),
    ...mapGetters('currencies', ['getCurrencyByTitle']),

    priceEquivalentList() {
      const { PRICE_EQUIVALENT_CURRENCY_LIST } = constant.currencies;
      const list = PRICE_EQUIVALENT_CURRENCY_LIST.map((e) => this.getCurrencyByTitle(e));
      return list;
    },
  },

  watch: {
    filters: {
      async handler(data) {
        await this.setFilters({
          ...data,
          ignoreUserTagList: data.ignoreUserTagList
            ? constant.reports.IGNORE_USER_TAG_LIST
            : [],
        });
        this.$emit('update');
      },
      deep: true,
    },

    tradingOptions() {
      const { PRICE_EQUIVALENT_CURRENCY_LIST } = constant.currencies;
      const { id } = this.getCurrencyByTitle(PRICE_EQUIVALENT_CURRENCY_LIST[0]);
      this.filters.cumulativeCurrencyId = id;
    },
  },

  methods: {
    ...mapActions('marketsCurrencyPairs', ['setFilters']),
  },
};
